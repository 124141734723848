<template>
  <v-container fluid class="bgImg">
    <v-row style="height: 100%">
      <v-col cols="6" sm="8" md="10" class="gradient pt-4 pb-3 pr-2 mt-5" style="z-index: 10"></v-col>
      <v-col cols="12" class="pa-0" style="height: 100%">
        <v-container fluid style="height: 100%" class="pa-0">
          <v-row style="height: 100%" class="pa-5">
            <v-col offset="1" offset-md="1" cols="6" md="5" class="mt-4">
              <p class="ma-0 text-md-h4 text-h6 textColor text-left font-weight-medium">Nos estamos<br>
                <span class="text-md-h3 text-h5 font-weight-medium">Renovando</span>
              </p>
            </v-col>
            <v-col offset-md="4" offset="2" cols="10" md="8" class="d-flex align-self-end mb-16">
              <p class="ma-0 text-md-h5 text-body-2 textColor text-left font-weight-bold">
                Mientras tanto, <br>
                <span class="text-md-h4 text-body-1 font-weight-bold"> Líderes y Shapers de la región </span> <br>
                siguen viviendo sus viajes de transformación con nosotros
                <br>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'LandingPage'
  }
</script>

<style scoped>
  .gradient {
    background: rgb(8, 44, 101);
    background: linear-gradient(93deg, rgba(8, 44, 101, 1) 0%, rgba(122, 163, 78, 1) 63%, rgba(255, 255, 255, 1) 100%);
  }

  .textColor {
    color: #204A6C
  }

  .bgImg {
    background-image: url("../assets/images/birdsBG.jpg");

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
